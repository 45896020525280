.home {
  margin: -16px -40px;
  padding: 16px 40px;
  background-color: "#F1F2F8";
}

.home-banner {
  padding-top: 32px;
  
}

.home-banner h1 {
  color: white;
  margin-bottom: 16px;
  font-size: 36px;
}

.home-banner h2 {
  margin-top: 16px;
  color: white;
  font-size: 30px;
  line-height: 30px;
  font-weight: normal;
}

.home-banner h6 {
  color: white;
  font-size: 14px;
  font-weight: normal;
}

.home-img-div {
  float: left;
  padding: 15px;
  width:290px;
}

.home-img-header {
  font-weight: bold;
  font-size: 24px;
}

.home-imgs {
  display:block;
  height:350px;
}

.downSize {
  font-size: 24px;
}


.award-view-bg{
  background-color: black;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  position: fixed;
  z-index:1003;
  background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);

}

.award-winner{
  border: solid 1px #FFF;
  margin: 25px;
  padding: 10px;
  padding-top:10px;
  padding-bottom:15px;
  width: 19%;
  float: left;
  color: white;
  display: block;
  height: 300px;
  background-color: #FBAB7E;
  background:linear-gradient(10deg, #BF953F, #FCF6BA, #B38728);

}

.award-winners-banner {
  width:90%;
  margin:0 auto;
  margin-top:50px;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  color: #fffb00;
  height: 70px;
  background: rgb(247,165,0);
  background-color: #FAD961;
background-image: linear-gradient(165deg, #FAD961 0%, #F76B1C 100%);

  line-height: 64px;
}

#awardees {
  width:90%;
  margin-top:10px;
  border:solid 1px; 
  border-color: #FFF;
  margin-left: 5%;
  height:80%;
  display: inline-block;
  background-color: #0093E9;
background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);

}

.award-card-award-name {
  width:100%;
  text-align: center;
  margin-bottom:10px;
  font-weight: bold;
  font-size:18px;
  height:15%; 
  color:#875a00;
  background-color:#ffe389;
  border: solid 1px #cdb900;
}

.award-card-photo {
  margin:0 auto;
  display: block;
  margin-bottom: 10px;
  height: 70%
}

.award-card-awardee-name {
  width:80%;
  margin: 0 auto;
  text-align: center;
  font-weight: bold;
  font-size:18px;
  padding-top:3px;
  padding-bottom:3px;
  color:#875a00;
  background-color:#ffe389;
  border: solid 1px #cdb900;
}
