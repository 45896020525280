/* unvisited link */
a {
    color: #034afc;
}
a:link {
    color: #034afc;
  }
  
  /* visited link */
  a:visited {
    color: green;
  }
  
  /* mouse over link */
  a:hover {
    color: darkblue;
    cursor: pointer;
    text-decoration: underline;
  }
  
  /* selected link */
  a:active {
    color: #034afc;
  }